
import { defineComponent } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { InboxStore, RootStore } from "@/store/constants";
import { MdInputField } from "@/components/md/MdInput";
import { MdTooltip } from "@/components/md/MdTooltip";
import { ITagColor } from "@/types/inbox/tags";
import { Validator } from "@/classes/validators/Validator";
import { TextValidator } from "@/classes/validators/TextValidator";
import { createTag } from "@/api/inbox/settings/tags/create";

export default defineComponent({
  name: "TagCreateModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdModal,
    MdButton,
    MdInputField,
    MdTooltip,
  },
  data() {
    return {
      name: "",
      color: "grey",
    };
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  computed: {
    colors(): { [key: string]: ITagColor } {
      return this.store.getters[InboxStore.Getters.GET_TAG_COLORS];
    },
    nameInput(): typeof MdInputField {
      return this.$refs.nameInput as typeof MdInputField;
    },
    nameValidator(): Validator {
      return new TextValidator(true);
    },
  },
  methods: {
    async createTag() {
      this.nameInput.validate();
      if (!this.nameValidator.isValid()) return;
      this.setLoadingState(true);
      try {
        await createTag(this.name, this.color);
        this.store.dispatch(InboxStore.Actions.LOAD_TAGS);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Tag erstellt",
        });
      } catch (err) {
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Tag konnte nicht erstellt werden",
        });
      }
      this.name = "";
      this.color = "grey";
      this.setLoadingState(false);
      this.close();
    },
  },
});

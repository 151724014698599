import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import {
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

/**
 * Updates a tag
 * @param id of the tag
 * @param name of the tag (new name)
 * @param color of the tag (new color)
 */
export const updateTag = async (
  id: string,
  name: string,
  color: string
): Promise<void> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  await updateDoc(
    doc(getFirestore(), "dialogs/" + chatbotId + "/settings/inbox/tags/" + id),
    {
      name,
      color,
      updated: serverTimestamp(),
    }
  );
};

import {
  addDoc,
  collection,
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import { ICustomFieldInput } from "@/types/inbox/fields";

export const updateFields = async (
  id: string,
  name: string,
  fields: ICustomFieldInput[]
): Promise<void> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  const createdDoc = await updateDoc(
    doc(
      getFirestore(),
      "dialogs/" + chatbotId + "/settings/inbox/fields/" + id
    ),
    {
      name,
      fields,
      updated: serverTimestamp(),
    }
  );
};

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";

/**
 * Deletes a custom view
 * @param id of the view
 */
export const deleteView = async (id: string): Promise<void> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  await deleteDoc(
    doc(getFirestore(), "dialogs/" + chatbotId + "/settings/inbox/views/" + id)
  );
};

import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import { deleteDoc, doc, getFirestore } from "firebase/firestore";

/**
 * Deletes a tag from the inbox settings.
 * @param id of the tag
 */
export const deleteTag = async (id: string): Promise<void> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  await deleteDoc(
    doc(getFirestore(), "dialogs/" + chatbotId + "/settings/inbox/tags/" + id)
  );
};

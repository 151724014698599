
import { defineComponent } from "vue";
import MdTabs from "@/components/md/MdTabs/MdTabs.vue";
import MdTable from "@/components/md/MdTable/MdTable.vue";
import MdTableColumn from "@/components/md/MdTable/MdTableColumn.vue";
import MdFabExtended from "@/components/md/MdFab/MdFabExtended.vue";
import TagCreateModal from "@/components/inbox/settings/TagCreateModal.vue";
import { IMdModal } from "@/components/md/MdDialog";
import { ITag } from "@/types/inbox/tags";
import { useStore } from "@/store";
import { InboxStore } from "@/store/constants";
import { IRow, IRowBadge, IRowButton } from "@/components/md/MdTable";
import { DateFormat } from "@/classes/DateFormat";
import EmptyHint from "@/components/cc/EmptyHint/EmptyHint.vue";
import TagUpdateModal from "@/components/inbox/settings/TagUpdateModal.vue";
import TagDeleteModal from "@/components/inbox/settings/TagDeleteModal.vue";

export default defineComponent({
  name: "InboxSettingsTags",
  components: {
    TagDeleteModal,
    TagUpdateModal,
    EmptyHint,
    TagCreateModal,
    MdFabExtended,
    MdTableColumn,
    MdTable,
    MdTabs,
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  data() {
    return {
      targetTag: null as ITag | null,
    };
  },
  computed: {
    tags(): ITag[] | null {
      return this.store.getters[InboxStore.Getters.GET_TAGS];
    },
    rows(): IRow[] {
      const rows: IRow[] = [];
      if (!this.tags) return rows;
      const updateButton: IRowButton = {
        icon: "edit",
        tooltip: "Bearbeiten",
      };
      const deleteButton: IRowButton = {
        icon: "delete",
        tooltip: "Löschen",
      };
      this.tags.forEach((tag) => {
        const color = this.store.getters[InboxStore.Getters.GET_TAG_COLOR](
          tag.color
        );
        rows.push({
          ...tag,
          color: {
            text: color.name,
            color: color.color,
          } as IRowBadge,
          updated: DateFormat.formatDate(tag.updated.toDate()),
          update: updateButton,
          delete: deleteButton,
          tag,
        });
      });
      return rows;
    },
    createModal(): IMdModal {
      return this.$refs.createModal as IMdModal;
    },
    updateModal(): IMdModal {
      return this.$refs.updateModal as IMdModal;
    },
    deleteModal(): IMdModal {
      return this.$refs.deleteModal as IMdModal;
    },
  },
  methods: {
    onTableButtonClick(column: string, row: IRow) {
      this.targetTag = row.tag;
      switch (column) {
        case "update":
          this.updateModal.open();
          break;
        case "delete":
          this.deleteModal.open();
          break;
      }
    },
  },
});


import { defineComponent } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { InboxStore, RootStore } from "@/store/constants";
import { MdInputField } from "@/components/md/MdInput";
import { Validator } from "@/classes/validators/Validator";
import { TextValidator } from "@/classes/validators/TextValidator";
import { ITag } from "@/types/inbox/tags";
import EmptyHint from "@/components/cc/EmptyHint/EmptyHint.vue";
import MdCheckbox from "@/components/md/MdCheckbox/MdCheckbox.vue";
import MdBadge from "@/components/md/MdBadge/MdBadge.vue";
import { createView } from "@/api/inbox/settings/views/create";
import { MdRadio } from "@/components/md/MdRadio";

export default defineComponent({
  name: "ViewCreateModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdBadge,
    MdCheckbox,
    EmptyHint,
    MdModal,
    MdButton,
    MdInputField,
    MdRadio,
  },
  data() {
    return {
      name: "",
      selectedTags: [] as string[],
      selectedFields: [] as string[],
      selectedSort: "none" as string,
    };
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  computed: {
    nameInput(): typeof MdInputField {
      return this.$refs.nameInput as typeof MdInputField;
    },
    nameValidator(): Validator {
      return new TextValidator(true);
    },
    tags(): ITag[] | null {
      return this.store.getters[InboxStore.Getters.GET_TAGS];
    },
    fields(): { name: string; value: string; color: string }[] {
      return [
        {
          name: "Ungelesen",
          value: "unread",
          color: "grey",
        },
      ];
    },
    sorts(): { name: string; id: string }[] {
      return [
        { name: "Unsortiert", id: "none" },
        { name: "Aktivität", id: "updated" },
      ];
    },
  },
  methods: {
    getColor(colorName: string) {
      return this.store.getters[InboxStore.Getters.GET_TAG_COLOR](colorName)
        .color;
    },
    async createView() {
      this.nameInput.validate();
      if (!this.nameValidator.isValid()) return;
      this.setLoadingState(true);
      try {
        await createView(
          this.name,
          this.selectedTags,
          this.selectedFields,
          this.selectedSort
        );
        this.store.dispatch(InboxStore.Actions.LOAD_VIEWS);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Ansicht erstellt",
        });
      } catch (err) {
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Ansicht konnte nicht erstellt werden",
        });
      }
      this.name = "";
      this.setLoadingState(false);
      this.close();
    },
  },
});

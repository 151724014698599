
import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { InboxStore, RootStore } from "@/store/constants";
import { MdInputField } from "@/components/md/MdInput";
import { MdTooltip } from "@/components/md/MdTooltip";
import { ITag, ITagColor } from "@/types/inbox/tags";
import { Validator } from "@/classes/validators/Validator";
import { TextValidator } from "@/classes/validators/TextValidator";
import { createTag } from "@/api/inbox/settings/tags/create";
import { updateTag } from "@/api/inbox/settings/tags/update";
import { deleteTag } from "@/api/inbox/settings/tags/delete";
import { ICustomView } from "@/types/inbox/views";
import { deleteView } from "@/api/inbox/settings/views/delete";
import { ICustomField } from "@/types/inbox/fields";
import { deleteField } from "@/api/inbox/settings/fields/delete";

export default defineComponent({
  name: "FieldDeleteModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdModal,
    MdButton,
  },
  props: {
    field: Object as PropType<ICustomField>,
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  methods: {
    async deleteField() {
      this.setLoadingState(true);
      if (!this.field || !this.field.id) return;
      try {
        await deleteField(this.field.id);
        this.store.dispatch(InboxStore.Actions.LOAD_FIELDS);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Abschnitt gelöscht",
        });
      } catch (err) {
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Abschnitt konnte nicht gelöscht werden",
        });
      }
      this.setLoadingState(false);
      this.close();
    },
  },
});

/**
 * Creates a new answer
 * @param name of the answer
 * @param color of the tag as name (e.g. "red")
 * @returns id of the created answer
 */
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";

export const createAnswer = async (
  name: string,
  message: string
): Promise<string> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  const createdDoc = await addDoc(
    collection(
      getFirestore(),
      "dialogs/" + chatbotId + "/settings/inbox/answers"
    ),
    {
      name,
      message,
      created: serverTimestamp(),
      updated: serverTimestamp(),
    }
  );
  return createdDoc.id;
};

/**
 * Creates a new tag
 * @param name of the tag
 * @param color of the tag as name (e.g. "red")
 * @returns id of the created tag
 */
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";

export const createTag = async (
  name: string,
  color: string
): Promise<string> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  const createdDoc = await addDoc(
    collection(getFirestore(), "dialogs/" + chatbotId + "/settings/inbox/tags"),
    {
      name,
      color,
      created: serverTimestamp(),
      updated: serverTimestamp(),
    }
  );
  return createdDoc.id;
};

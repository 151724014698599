import {
  addDoc,
  collection,
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";

/**
 * Updates a custom view.
 * @param id of the view
 * @param name of the view
 * @param tags of the view to display conversations with
 * @param fields to display conversations with
 * @param sort to display conversations with
 */
export const updateView = async (
  id: string,
  name: string,
  tags: string[],
  fields: string[],
  sort: string
): Promise<void> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  await updateDoc(
    doc(getFirestore(), "dialogs/" + chatbotId + "/settings/inbox/views/" + id),
    {
      name,
      tags,
      fields,
      sort,
      updated: serverTimestamp(),
    }
  );
};

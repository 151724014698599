
import { defineComponent } from "vue";
import MdTabs from "@/components/md/MdTabs/MdTabs.vue";
import MdTable from "@/components/md/MdTable/MdTable.vue";
import MdTableColumn from "@/components/md/MdTable/MdTableColumn.vue";
import MdFabExtended from "@/components/md/MdFab/MdFabExtended.vue";
import { IMdModal } from "@/components/md/MdDialog";
import { useStore } from "@/store";
import { InboxStore } from "@/store/constants";
import { IRow, IRowButton } from "@/components/md/MdTable";
import { DateFormat } from "@/classes/DateFormat";
import EmptyHint from "@/components/cc/EmptyHint/EmptyHint.vue";
import ViewCreateModal from "@/components/inbox/settings/ViewCreateModal.vue";
import { ICustomView } from "@/types/inbox/views";
import ViewUpdateModal from "@/components/inbox/settings/ViewUpdateModal.vue";
import ViewDeleteModal from "@/components/inbox/settings/ViewDeleteModal.vue";
import { useCookies } from "vue3-cookies";

const sortNames = {
  none: "Unsortiert",
  updated: "Aktivität",
};

export default defineComponent({
  name: "InboxSettingsViews",
  components: {
    ViewDeleteModal,
    ViewUpdateModal,
    ViewCreateModal,
    EmptyHint,
    MdFabExtended,
    MdTableColumn,
    MdTable,
    MdTabs,
  },
  setup() {
    const { cookies } = useCookies();
    return {
      cookies,
      store: useStore(),
      rows: [] as IRow[],
    };
  },
  data() {
    return {
      targetView: null as ICustomView | null,
      tableKey: 0,
    };
  },
  watch: {
    views: {
      immediate: true,
      handler() {
        this.rows = this.generateRows();
      },
    },
  },
  computed: {
    views(): ICustomView[] | null {
      return this.store.getters[InboxStore.Getters.GET_VIEWS];
    },
    createModal(): IMdModal {
      return this.$refs.createModal as IMdModal;
    },
    updateModal(): IMdModal {
      return this.$refs.updateModal as IMdModal;
    },
    deleteModal(): IMdModal {
      return this.$refs.deleteModal as IMdModal;
    },
  },
  methods: {
    generateRows(): IRow[] {
      const rows: IRow[] = [];
      if (!this.views) return rows;
      const updateButton: IRowButton = {
        icon: "edit",
        tooltip: "Bearbeiten",
      };
      const deleteButton: IRowButton = {
        icon: "delete",
        tooltip: "Löschen",
      };
      const cookieInfo = JSON.parse(this.cookies.get("views_hidden")) || [];
      this.views.forEach((view) => {
        rows.push({
          name: view.name,
          tags:
            view.tags?.map((tag) => this.getTagName(tag)).join(", ") || "Keine",
          fields:
            view.fields?.map((field) => this.getFieldName(field)).join(", ") ||
            "Keine",
          sort: view.sort ? this.getSortName(view.sort) : "Keine",
          visibility: cookieInfo.includes(view.id)
            ? "Ausgeblendet"
            : "Eingeblendet",
          updated: DateFormat.formatDate(view.updated.toDate()),
          show: {
            icon: cookieInfo.includes(view.id)
              ? "visibility"
              : "visibility_off",
            tooltip: cookieInfo.includes(view.id) ? "Anzeigen" : "Verstecken",
          } as IRowButton,
          update: updateButton,
          delete: deleteButton,
          view,
        });
      });
      return rows;
    },
    onTableButtonClick(column: string, row: IRow) {
      this.targetView = row.view;
      switch (column) {
        case "update":
          this.updateModal.open();
          break;
        case "delete":
          this.deleteModal.open();
          break;
        case "show": {
          const viewId = row.view.id;
          const viewsHidden =
            JSON.parse(this.cookies.get("views_hidden")) || [];
          // if the view is saved in the cookie, remove it from the cookie else add it
          if (viewsHidden.includes(viewId)) {
            viewsHidden.splice(viewsHidden.indexOf(viewId), 1);
          } else {
            viewsHidden.push(viewId);
          }
          // set the correct cookies
          this.cookies.set("views_hidden", JSON.stringify(viewsHidden));
          // and reload the table
          this.rows = this.generateRows();
          this.tableKey += 1;

          break;
        }
      }
    },
    getTagName(id: string): string {
      const tag = this.store.getters[InboxStore.Getters.GET_TAG](id);
      return tag ? tag.name : "Unbekannt";
    },
    getFieldName(id: string): string {
      return id === "unread" ? "Ungelesen" : "Zurückgestellt";
    },
    getSortName(id: string): string {
      return sortNames[id];
    },
  },
});

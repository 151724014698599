import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fields" }
const _hoisted_2 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_tabs = _resolveComponent("md-tabs")!
  const _component_md_table_column = _resolveComponent("md-table-column")!
  const _component_md_table = _resolveComponent("md-table")!
  const _component_empty_hint = _resolveComponent("empty-hint")!
  const _component_md_fab_extended = _resolveComponent("md-fab-extended")!
  const _component_field_create_overlay = _resolveComponent("field-create-overlay")!
  const _component_field_update_overlay = _resolveComponent("field-update-overlay")!
  const _component_field_delete_modal = _resolveComponent("field-delete-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_md_tabs, {
      "title-only": true,
      title: "Individuelle Felder"
    }),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.fields || _ctx.fields.length > 0)
        ? (_openBlock(), _createBlock(_component_md_table, {
            key: 0,
            rows: _ctx.rows,
            onButtonClick: _ctx.onTableButtonClick
          }, {
            default: _withCtx(() => [
              _createVNode(_component_md_table_column, {
                title: "Name",
                display: "name"
              }),
              _createVNode(_component_md_table_column, {
                title: "Felder",
                display: "fields"
              }),
              _createVNode(_component_md_table_column, {
                display: "update",
                type: "button",
                title: ""
              }),
              _createVNode(_component_md_table_column, {
                display: "delete",
                type: "button",
                title: ""
              })
            ]),
            _: 1
          }, 8, ["rows", "onButtonClick"]))
        : (_openBlock(), _createBlock(_component_empty_hint, {
            key: 1,
            title: "Keine individuellen Felder erstellt",
            description: "Es wurden bisher noch keine individuellen Felder erstellt"
          }))
    ]),
    _createVNode(_component_md_fab_extended, {
      icon: "add_circle",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createOverlay.open()))
    }, {
      default: _withCtx(() => [
        _createTextVNode("Abschnitt erstellen")
      ]),
      _: 1
    }),
    _createVNode(_component_field_create_overlay, { ref: "createOverlay" }, null, 512),
    _createVNode(_component_field_update_overlay, {
      ref: "updateOverlay",
      field: _ctx.targetField
    }, null, 8, ["field"]),
    _createVNode(_component_field_delete_modal, {
      ref: "deleteModal",
      field: _ctx.targetField
    }, null, 8, ["field"])
  ]))
}

import { defineComponent, PropType } from "vue";
import { MdOverlay, MdOverlayComponentMixin } from "@/components/md/MdOverlay";
import { IMdInput, MdInputField } from "@/components/md/MdInput";
import { MdButton } from "@/components/md/MdButton";
import { MdCard } from "@/components/md/MdCard";
import { useStore } from "@/store";
import { InboxStore, RootStore } from "@/store/constants";
import MdSelect from "@/components/md/MdInput/MdSelect.vue";
import { IMenuItem } from "@/components/md/MdMenu";
import { ICustomField, ICustomFieldInput } from "@/types/inbox/fields";
import { createFields } from "@/api/inbox/settings/fields/create";
import { updateFields } from "@/api/inbox/settings/fields/update";
import MdAlert from "@/components/md/MdAlert/MdAlert.vue";

export default defineComponent({
  name: "FieldUpdateOverlay",
  mixins: [MdOverlayComponentMixin],
  components: {
    MdAlert,
    MdSelect,
    MdCard,
    MdOverlay,
    MdInputField,
    MdButton,
  },
  emits: ["close"],
  props: {
    field: Object as PropType<ICustomField>,
  },
  setup() {
    return { store: useStore() };
  },
  data() {
    return {
      name: "",
      fields: [
        {
          label: "",
          name: "",
          type: "text",
        },
      ] as ICustomFieldInput[],
      types: [
        {
          label: "Text",
          value: "text",
        },
        {
          label: "Nummer",
          value: "number",
        },
        {
          label: "Checkbox",
          value: "boolean",
        },
        {
          label: "Datei",
          value: "file",
        },
        {
          label: "Dropdown",
          value: "select",
        },
        {
          label: "Link",
          value: "link",
        },
      ] as IMenuItem[],
    };
  },
  watch: {
    field: {
      immediate: true,
      handler() {
        if (!this.field) return;
        this.name = this.field.name;
        this.fields = this.field.fields;
        this.fields.forEach((field) => {
          if (field.values) field.rawValues = field.values.join(",");
        });
      },
    },
  },
  computed: {
    nameInput(): IMdInput {
      return this.$refs.nameInput as IMdInput;
    },
    dbSectionName(): string {
      return this.name.toLowerCase().replace(/[^0-9a-zA-Z]+/g, "_");
    },
  },
  methods: {
    onLabelChange(field: ICustomFieldInput) {
      field.name = field.label.toLowerCase().replace(/[^0-9a-zA-Z]+/g, "_");
    },
    addField() {
      this.fields.push({
        label: "",
        name: "",
        type: "text",
      });
    },
    moveField(index: number, newIndex: number) {
      if (newIndex < 0 || newIndex >= this.fields.length) return;
      if (index === newIndex) return;
      const field = this.fields.splice(index, 1)[0];
      this.fields.splice(newIndex, 0, field);
    },
    async updateSection() {
      this.setLoading(true);
      if (!this.field || !this.field.id) return;
      try {
        this.fields.forEach((field) => {
          field.name = this.dbSectionName + "." + field.name;
          if (field.type === "select" && field.rawValues) {
            field.values = field.rawValues.split(",");
          }
        });
        await updateFields(this.field.id, this.name, this.fields);
        this.store.dispatch(InboxStore.Actions.LOAD_FIELDS);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Änderungen gespeichert",
        });
      } catch (err) {
        console.log("error: ", err);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Änderungen konnten nicht gespeichert werden",
        });
      }
      this.$emit("close");
      this.setLoading(false);
      this.close();
    },
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tags" }
const _hoisted_2 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_tabs = _resolveComponent("md-tabs")!
  const _component_md_table_column = _resolveComponent("md-table-column")!
  const _component_md_table = _resolveComponent("md-table")!
  const _component_empty_hint = _resolveComponent("empty-hint")!
  const _component_md_fab_extended = _resolveComponent("md-fab-extended")!
  const _component_view_create_modal = _resolveComponent("view-create-modal")!
  const _component_view_update_modal = _resolveComponent("view-update-modal")!
  const _component_view_delete_modal = _resolveComponent("view-delete-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_md_tabs, {
      "title-only": true,
      title: "Individuelle Ansichten"
    }),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.views || _ctx.views.length > 0)
        ? (_openBlock(), _createBlock(_component_md_table, {
            key: _ctx.tableKey,
            ref: "table",
            rows: _ctx.rows,
            onButtonClick: _ctx.onTableButtonClick
          }, {
            default: _withCtx(() => [
              _createVNode(_component_md_table_column, {
                title: "Name",
                display: "name"
              }),
              _createVNode(_component_md_table_column, {
                title: "Tags",
                display: "tags"
              }),
              _createVNode(_component_md_table_column, {
                title: "Eigenschaften",
                display: "fields"
              }),
              _createVNode(_component_md_table_column, {
                title: "Sortierung",
                display: "sort"
              }),
              _createVNode(_component_md_table_column, {
                title: "Sichtbarkeit",
                display: "visibility"
              }),
              _createVNode(_component_md_table_column, {
                display: "show",
                type: "button",
                title: ""
              }),
              _createVNode(_component_md_table_column, {
                display: "update",
                type: "button",
                title: ""
              }),
              _createVNode(_component_md_table_column, {
                display: "delete",
                type: "button",
                title: ""
              })
            ]),
            _: 1
          }, 8, ["rows", "onButtonClick"]))
        : (_openBlock(), _createBlock(_component_empty_hint, {
            key: 1,
            title: "Keine individuellen Ansichten erstellt",
            description: "Es wurden bisher noch keine individuellen Ansichten erstellt"
          }))
    ]),
    _createVNode(_component_md_fab_extended, {
      icon: "add_circle",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createModal.open()))
    }, {
      default: _withCtx(() => [
        _createTextVNode("Ansicht erstellen")
      ]),
      _: 1
    }),
    _createVNode(_component_view_create_modal, { ref: "createModal" }, null, 512),
    _createVNode(_component_view_update_modal, {
      ref: "updateModal",
      view: _ctx.targetView
    }, null, 8, ["view"]),
    _createVNode(_component_view_delete_modal, {
      ref: "deleteModal",
      view: _ctx.targetView
    }, null, 8, ["view"])
  ]))
}
import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import {
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

/**
 * Updates a answer
 * @param id of the answer
 * @param message of the answer (new message)
 */
export const updateAnswer = async (
  id: string,
  message: string
): Promise<void> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  await updateDoc(
    doc(
      getFirestore(),
      "dialogs/" + chatbotId + "/settings/inbox/answers/" + id
    ),
    {
      message,
      updated: serverTimestamp(),
    }
  );
};

import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";

/**
 * Creates a new custom view.
 * @param name of the view
 * @param tags to display conversations with
 * @param fields to display conversations with
 * @param sort to display conversations with
 */
export const createView = async (
  name: string,
  tags: string[],
  fields: string[],
  sort: string
): Promise<string> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  const createdDoc = await addDoc(
    collection(
      getFirestore(),
      "dialogs/" + chatbotId + "/settings/inbox/views"
    ),
    {
      name,
      tags,
      fields,
      sort,
      created: serverTimestamp(),
      updated: serverTimestamp(),
    }
  );
  return createdDoc.id;
};


import { defineComponent } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { InboxStore, RootStore } from "@/store/constants";
import { MdInputField } from "@/components/md/MdInput";
import { MdTooltip } from "@/components/md/MdTooltip";
import { ITagColor } from "@/types/inbox/tags";
import { Validator } from "@/classes/validators/Validator";
import { TextValidator } from "@/classes/validators/TextValidator";
import { createTag } from "@/api/inbox/settings/tags/create";
import MdTextarea from "@/components/md/MdInput/MdTextarea.vue";
import { createAnswer } from "@/api/inbox/settings/answers/create";

export default defineComponent({
  name: "AnswerCreateModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdTextarea,
    MdModal,
    MdButton,
    MdInputField,
  },
  data() {
    return {
      name: "",
      message: "",
    };
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  computed: {
    nameInput(): typeof MdInputField {
      return this.$refs.nameInput as typeof MdInputField;
    },
    messageInput(): typeof MdInputField {
      return this.$refs.messageInput as typeof MdInputField;
    },
    nameValidator(): Validator {
      return new TextValidator(true);
    },
    messageValidator(): Validator {
      return new TextValidator(true);
    },
  },
  methods: {
    async createAnswer() {
      this.nameInput.validate();
      if (!this.nameValidator.isValid() || !this.messageValidator.isValid())
        return;
      this.setLoadingState(true);
      try {
        await createAnswer(this.name, this.message);
        this.store.dispatch(InboxStore.Actions.LOAD_ANSWERS);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Schnellantwort erstellt",
        });
      } catch (err) {
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Schnellantwort konnte nicht erstellt werden",
        });
      }
      this.name = "";
      this.message = "";
      this.setLoadingState(false);
      this.close();
    },
  },
});

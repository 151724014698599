
import { defineComponent } from "vue";
import MdTabs from "@/components/md/MdTabs/MdTabs.vue";
import MdTable from "@/components/md/MdTable/MdTable.vue";
import MdTableColumn from "@/components/md/MdTable/MdTableColumn.vue";
import MdFabExtended from "@/components/md/MdFab/MdFabExtended.vue";
import TagCreateModal from "@/components/inbox/settings/TagCreateModal.vue";
import { IMdModal } from "@/components/md/MdDialog";
import { ITag } from "@/types/inbox/tags";
import { useStore } from "@/store";
import { InboxStore } from "@/store/constants";
import { IRow, IRowBadge, IRowButton } from "@/components/md/MdTable";
import { DateFormat } from "@/classes/DateFormat";
import EmptyHint from "@/components/cc/EmptyHint/EmptyHint.vue";
import TagUpdateModal from "@/components/inbox/settings/TagUpdateModal.vue";
import TagDeleteModal from "@/components/inbox/settings/TagDeleteModal.vue";
import AnswerCreateModal from "@/components/inbox/settings/AnswerCreateModal.vue";
import { IAnswer } from "@/types/inbox/answers";
import AnswerUpdateModal from "@/components/inbox/settings/AnswerUpdateModal.vue";
import AnswerDeleteModal from "@/components/inbox/settings/AnswerDeleteModal.vue";

export default defineComponent({
  name: "InboxSettingsTags",
  components: {
    AnswerDeleteModal,
    AnswerUpdateModal,
    EmptyHint,
    AnswerCreateModal,
    MdFabExtended,
    MdTableColumn,
    MdTable,
    MdTabs,
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  data() {
    return {
      targetAnswer: null as IAnswer | null,
    };
  },
  computed: {
    answers(): IAnswer[] | null {
      return this.store.getters[InboxStore.Getters.GET_ANSWERS];
    },
    rows(): IRow[] {
      const rows: IRow[] = [];
      if (!this.answers) return rows;
      const updateButton: IRowButton = {
        icon: "edit",
        tooltip: "Bearbeiten",
      };
      const deleteButton: IRowButton = {
        icon: "delete",
        tooltip: "Löschen",
      };
      this.answers.forEach((answer) => {
        rows.push({
          ...answer,
          updated: DateFormat.formatDate(answer.updated.toDate()),
          update: updateButton,
          delete: deleteButton,
          answer,
        });
      });
      return rows;
    },
    createModal(): IMdModal {
      return this.$refs.createModal as IMdModal;
    },
    updateModal(): IMdModal {
      return this.$refs.updateModal as IMdModal;
    },
    deleteModal(): IMdModal {
      return this.$refs.deleteModal as IMdModal;
    },
  },
  methods: {
    onTableButtonClick(column: string, row: IRow) {
      this.targetAnswer = row.answer;
      switch (column) {
        case "update":
          this.updateModal.open();
          break;
        case "delete":
          this.deleteModal.open();
          break;
      }
    },
  },
});

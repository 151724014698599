
import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { InboxStore, RootStore } from "@/store/constants";
import { MdInputField } from "@/components/md/MdInput";
import { MdTooltip } from "@/components/md/MdTooltip";
import { ITag, ITagColor } from "@/types/inbox/tags";
import { Validator } from "@/classes/validators/Validator";
import { TextValidator } from "@/classes/validators/TextValidator";
import { createTag } from "@/api/inbox/settings/tags/create";
import { updateTag } from "@/api/inbox/settings/tags/update";
import { deleteTag } from "@/api/inbox/settings/tags/delete";
import { IAnswer } from "@/types/inbox/answers";
import { deleteAnswer } from "@/api/inbox/settings/answers/delete";

export default defineComponent({
  name: "AnswerDeleteModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdModal,
    MdButton,
  },
  props: {
    answer: Object as PropType<IAnswer>,
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  methods: {
    async deleteAnswer() {
      this.setLoadingState(true);
      if (!this.answer || !this.answer.id) return;
      try {
        await deleteAnswer(this.answer.id);
        this.store.dispatch(InboxStore.Actions.LOAD_ANSWERS);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Schnellantwort gelöscht",
        });
      } catch (err) {
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Schnellantwort konnte nicht gelöscht werden",
        });
      }
      this.setLoadingState(false);
      this.close();
    },
  },
});

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_input_field = _resolveComponent("md-input-field")!
  const _component_md_textarea = _resolveComponent("md-textarea")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_modal = _resolveComponent("md-modal")!

  return (_openBlock(), _createBlock(_component_md_modal, {
    ref: "modal",
    title: "Schnellantwort erstellen",
    icon: "bolt"
  }, {
    default: _withCtx(() => [
      _createTextVNode(" Erstelle eine neue Schnellantwort und nutze diese anschließend im Posteingang, um deinen Kunden schneller zu antworten. Du kannst mit \"/\" auf alle Schnellantworten zugreifen. ")
    ]),
    body: _withCtx(() => [
      _createVNode(_component_md_input_field, {
        variant: "outlined",
        modelValue: _ctx.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
        label: "Name",
        ref: "nameInput",
        validator: _ctx.nameValidator,
        onKeypress: _withKeys(_ctx.createAnswer, ["enter"])
      }, null, 8, ["modelValue", "validator", "onKeypress"]),
      _createVNode(_component_md_textarea, {
        variant: "outlined",
        modelValue: _ctx.message,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.message) = $event)),
        label: "Antwort",
        ref: "messageInput",
        validator: _ctx.messageValidator
      }, null, 8, ["modelValue", "validator"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_md_button, {
        variant: "text",
        grey: "",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Abbrechen")
        ]),
        _: 1
      }),
      _createVNode(_component_md_button, {
        variant: "filled",
        onClick: _ctx.createAnswer
      }, {
        default: _withCtx(() => [
          _createTextVNode("Schnellantwort erstellen")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 512))
}
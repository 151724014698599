
import { defineComponent } from "vue";
import MdTabs from "@/components/md/MdTabs/MdTabs.vue";
import MdTable from "@/components/md/MdTable/MdTable.vue";
import MdTableColumn from "@/components/md/MdTable/MdTableColumn.vue";
import MdFabExtended from "@/components/md/MdFab/MdFabExtended.vue";
import { IMdModal } from "@/components/md/MdDialog";
import { useStore } from "@/store";
import { IRow, IRowButton } from "@/components/md/MdTable";
import { DateFormat } from "@/classes/DateFormat";
import EmptyHint from "@/components/cc/EmptyHint/EmptyHint.vue";
import AnswerUpdateModal from "@/components/inbox/settings/AnswerUpdateModal.vue";
import AnswerDeleteModal from "@/components/inbox/settings/AnswerDeleteModal.vue";
import { ICustomField } from "@/types/inbox/fields";
import FieldCreateOverlay from "@/components/inbox/settings/FieldCreateOverlay.vue";
import { IMdOverlay } from "@/components/md/MdOverlay";
import { InboxStore } from "@/store/constants";
import FieldUpdateOverlay from "@/components/inbox/settings/FieldUpdateOverlay.vue";
import FieldDeleteModal from "@/components/inbox/settings/FieldDeleteModal.vue";

export default defineComponent({
  name: "InboxSettingsFields",
  components: {
    FieldDeleteModal,
    FieldUpdateOverlay,
    FieldCreateOverlay,
    EmptyHint,
    MdFabExtended,
    MdTableColumn,
    MdTable,
    MdTabs,
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  data() {
    return {
      targetField: null as ICustomField | null,
    };
  },
  computed: {
    fields(): ICustomField[] | null {
      return this.store.getters[
        InboxStore.Getters.GET_FIELDS
      ] as ICustomField[];
    },
    rows(): IRow[] {
      const rows: IRow[] = [];
      if (!this.fields) return rows;
      const updateButton: IRowButton = {
        icon: "edit",
        tooltip: "Bearbeiten",
      };
      const deleteButton: IRowButton = {
        icon: "delete",
        tooltip: "Löschen",
      };
      this.fields.forEach((field) => {
        rows.push({
          name: field.name,
          fields: field.fields.map((field) => field.label).join(", "),
          updated: DateFormat.formatDate(field.updated.toDate()),
          update: updateButton,
          delete: deleteButton,
          field,
        });
      });
      return rows;
    },
    createOverlay(): IMdOverlay {
      return this.$refs.createOverlay as IMdOverlay;
    },
    updateOverlay(): IMdOverlay {
      return this.$refs.updateOverlay as IMdOverlay;
    },
    deleteModal(): IMdModal {
      return this.$refs.deleteModal as IMdModal;
    },
  },
  methods: {
    onTableButtonClick(column: string, row: IRow) {
      this.targetField = row.field;
      switch (column) {
        case "update":
          this.updateOverlay.open();
          break;
        case "delete":
          this.deleteModal.open();
          break;
      }
    },
  },
});


import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { InboxStore, RootStore } from "@/store/constants";
import { MdInputField } from "@/components/md/MdInput";
import { MdTooltip } from "@/components/md/MdTooltip";
import { ITagColor } from "@/types/inbox/tags";
import { Validator } from "@/classes/validators/Validator";
import { TextValidator } from "@/classes/validators/TextValidator";
import { createTag } from "@/api/inbox/settings/tags/create";
import MdTextarea from "@/components/md/MdInput/MdTextarea.vue";
import { createAnswer } from "@/api/inbox/settings/answers/create";
import { IAnswer } from "@/types/inbox/answers";
import { updateAnswer } from "@/api/inbox/settings/answers/update";

export default defineComponent({
  name: "AnswerUpdateModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdTextarea,
    MdModal,
    MdButton,
  },
  props: {
    answer: Object as PropType<IAnswer>,
  },
  data() {
    return {
      message: "",
    };
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  watch: {
    answer(answer: IAnswer) {
      this.message = answer.message;
    },
  },
  computed: {
    messageInput(): typeof MdInputField {
      return this.$refs.messageInput as typeof MdInputField;
    },
    messageValidator(): Validator {
      return new TextValidator(true);
    },
  },
  methods: {
    async updateAnswer() {
      this.messageInput.validate();
      if (!this.messageValidator.isValid()) return;
      if (!this.answer || !this.answer.id) return;
      this.setLoadingState(true);
      try {
        await updateAnswer(this.answer.id, this.message);
        this.store.dispatch(InboxStore.Actions.LOAD_ANSWERS);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Änderungen gespeichert",
        });
      } catch (err) {
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Änderungen konnten nicht gespeichert werden",
        });
      }
      this.message = "";
      this.setLoadingState(false);
      this.close();
    },
  },
});
